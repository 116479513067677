<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <div class="mb-2">
                    <mdb-btn color="secondary"
                             @click="add_supplier()">
                        <i class="fa fa-plus mr-2"></i>
                        &nbsp;新增供應商
                    </mdb-btn>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th style="width:50%">名稱</th>
                            <th class="text-center"
                                style="width:5em">啟用</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in supplierList"
                            :key="`store_${sk}`">
                            <td>{{sk+1}}</td>
                            <td @keypress.enter="save_supplier(s)">
                                <span v-show="!s.edit">{{s.name}}</span>
                                <mdb-input type="text"
                                           v-show="s.edit"
                                           v-model="s.name"
                                           class="my-0"
                                           placeholder="請輸入供應商名稱" />
                            </td>
                            <td class="text-center">
                                <span v-show="!s.edit">
                                    <i class="fa"
                                       :class="s.active?'fa-check':'fa-times'"></i>
                                </span>
                                <mdb-switch offLabel=""
                                            onLabel=''
                                            :checked="s.active"
                                            v-show="s.edit"
                                            @getValue="val=>s.active=val" />
                            </td>
                            <td>
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="s.edit=true"
                                         v-show="!s.edit">
                                    <i class="fa fa-edit mr-2"></i>
                                    編輯
                                </mdb-btn>
                                <mdb-btn color="default"
                                         size="sm"
                                         @click="save_supplier(s)"
                                         v-show="s.edit">
                                    <i class="fa fa-save mr-2"></i>
                                    儲存
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"
                                v-show="supplierList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import { mdbCard, mdbCardBody, mdbInput, mdbBtn, mdbSwitch } from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbSwitch,
  },
  data() {
    return {
      supplierList: [],
      supplierEmpty: {},
    };
  },
  mounted() {
    let vue = this,
      query = [
        {
          name: "supplier",
          data: {
            id: { type: 1, value: 0 },
          },
          sort: {
            active: 1,
            name: 0,
          },
        },
        {
          name: "supplier",
        },
      ];
    vue.$store
      .dispatch("get_form", {
        payload: {
          url: `data/get_data/?query=${JSON.stringify(query)}`,
        },
      })
      .then((res) => {
        vue.supplierList = res.data.supplierList.map((item) => {
          item.edit = false;
          item.ajaxing = false;
          item.active = item.active == "1";
          return item;
        });
        vue.supplierEmpty = res.data.supplierEmpty;
        vue.supplierEmpty.active = true;
        vue.supplierEmpty.edit = true;
        vue.supplierEmpty.ajaxing = false;
      });
  },
  methods: {
    add_supplier() {
      this.supplierList.unshift(Object.assign({}, this.supplierEmpty));
    },
    save_supplier(s) {
      let vue = this;
      if (!s.ajaxing && s.edit) {
        s.ajaxing = true;
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "data/supplier_save",
              data: { data: JSON.stringify(s) },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              s.id = res.data.supplier_id;
              s.edit = false;
              s.ajaxing = false;
            }
          });
      }
    },
  },
};
</script>